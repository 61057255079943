import { useQuery } from "@apollo/client";
import { Button, Checkbox, TableColumnsType } from "antd";
import dayjs from "dayjs";
import ExcelJS from "exceljs";
import { merge } from "lodash";
import { Link } from "react-router-dom";
import { QUERY_ENTRIES } from "../graphql";

function excelFormatCurrency(fractionDigits = 0) {
  if (fractionDigits === 0) {
    return "$#,##0_);($#,##0)";
  }
  if (fractionDigits > 0) {
    return `$#,##0.${"0".repeat(fractionDigits)}_);($#,##0.${"0".repeat(
      fractionDigits
    )})`;
  }
}

function formatCurrency(value?: number | null, fractionDigits = 0) {
  if (typeof value !== "number") return "";
  const absValue = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(Math.abs(value));
  return value < 0 ? `(${absValue})` : absValue;
}

function excelFormatPercent(fractionDigits = 2) {
  if (fractionDigits === 0) {
    return "0%";
  }
  if (fractionDigits > 0) {
    return `0.${"0".repeat(fractionDigits)}%`;
  }
}

function formatPercent(value?: number | null, fractionDigits = 2) {
  return typeof value !== "number"
    ? ""
    : new Intl.NumberFormat(undefined, {
        style: "percent",
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      }).format(value);
}

function parseDate(value?: any) {
  return !value
    ? undefined
    : // if UTC time, parse as local time
      dayjs(typeof value === "string" ? value.slice(0, 10) : value);
}

function formatDate(value?: dayjs.Dayjs) {
  return !value ? "" : value.format("M/D/YYYY");
}

export const MAX_PERIODS = 12;

enum AcctType {
  futures = 21212121,
  futuresCogency = 31313131,
  cash = 66666666,
  receivables = 77777777,
  liabilities = 88888888,
  shortTermRecs = 55555555,
  restrictedCash = 99999999,
  inflows = 90000001,
  outflows = 90000002,
}

const INVESTABLE_CASH_ACCT_TYPES = [AcctType.cash, AcctType.liabilities];

const ACCT_ORDER = new Map([
  [AcctType.cash, 0],
  [AcctType.liabilities, 1],
  [AcctType.futures, 2],
  [AcctType.futuresCogency, 3],
  [AcctType.shortTermRecs, 4],
  [AcctType.receivables, 5],
  [AcctType.restrictedCash, 6],
  [AcctType.inflows, 7],
  [AcctType.outflows, 8],
]);

export const IGNORE_TAGS = ["Inactive", "Redeem"];

export function useQueryEntries(variables: {
  portfolioId: number;
  date: string;
  periods: number;
  lookthrough: boolean;
}) {
  return useQuery(QUERY_ENTRIES, {
    variables,
    fetchPolicy: "no-cache",
  });
}

type Portfolio = NonNullable<
  NonNullable<ReturnType<typeof useQueryEntries>["data"]>["portfolio"]
>;

type Entry = NonNullable<Portfolio["entries"]>[number];

const nameSort = (a: EntryView, b: EntryView) => a.name.localeCompare(b.name);
const acctSort = (a: Entry, b: Entry) =>
  ACCT_ORDER.get(a.managerId)! - ACCT_ORDER.get(b.managerId)!;

enum RedemptionsSource {
  NavModel = 1,
  ManualEntry = 2,
  RedemptionsSystemTicketConfirmed = 3,
  RedemptionsSystemTicketApproved = 4,
}

export const GROUP_BY = {
  firm: (entry: Entry) => entry.initBalance.manager?.strategy?.name ?? "",
  client: (entry: Entry) => entry.initBalance.tags?.clientTag ?? "",
  team: (entry: Entry) => entry.initBalance.tags?.teamTag ?? "",
  open: (entry: Entry) => entry.initBalance.tags?.openTag ?? "",
  guideline: (entry: Entry) => entry.initBalance.tags?.guidelineTag ?? "",
} as const;

export type GroupBy = keyof typeof GROUP_BY;

interface TranView {
  beginBalance: number;
  estimatedReturn?: number;
  estimatedReturnDate?: dayjs.Dayjs;
}

interface BalanceView {
  additions: number;
  redemptions: number;
  redemptionsSource?: number;
  endBalance: number;
  weight: number;
  nonZeroCount: number;
}

interface LiquidityView {
  nextNotice?: dayjs.Dayjs;
  nextRedemption?: dayjs.Dayjs;
  quantity?: number;
  frequency?: number;
}

interface CommitmentView {
  amount: number;
  isPreInvestment: boolean;
}

export interface EntryView {
  key: string | number;
  mgrIndex?: number;
  managerId?: number;
  isAcct: boolean;
  name: string;
  firmTag?: string;
  clientTag?: string;
  teamTag?: string;
  openTag?: string;
  guidelineTag?: string;
  isLookthrough?: boolean;
  isLinkManager?: boolean;
  isLinkMutable?: boolean;
  isNoticePast?: boolean;
  isNoticeSoon?: boolean;
  initTran?: TranView;
  initBalance: BalanceView;
  initNonZeroCount?: number;
  balances: BalanceView[];
  liquidity?: LiquidityView;
  commitment?: CommitmentView;
  subitems?: EntryView[];
}

type HeaderAttrs<T> = ReturnType<
  NonNullable<TableColumnsType<T>[number]["onHeaderCell"]>
>;

export interface SharedColumn<T> {
  title?: string;
  width?: number;
  key?: string;
  fixed?: "left";
  align?: "left" | "center" | "right";
  ellipsis?: boolean;
  excelNumFmt?: string;
  onHeaderCell?: () => HeaderAttrs<T>;
  onCell?: TableColumnsType<T>[number]["onCell"];
  render?: TableColumnsType<T>[number]["render"];
  children?: SharedColumn<T>[];
}

const HEADER_COLOR = "#FAFAFA";
const BORDER_COLOR = "#F0F0F0";

function htmlColorToArgb(htmlColor?: string) {
  if (!htmlColor) return;
  htmlColor = htmlColor.toUpperCase();
  if (htmlColor.startsWith("#")) {
    const hex = htmlColor.slice(1);
    if (hex.length >= 6) {
      const rgb = hex.slice(0, 6);
      const a = hex.slice(6) || "FF";
      // swap
      return `${a}${rgb}`;
    }
    throw new Error(`Unsupported color format: ${htmlColor}`);
  }
  switch (htmlColor) {
    case "PINK":
      return "FFFFC0CB";
    case "CYAN":
      return "FF00FFFF";
    case "GOLD":
      return "FFFFD700";
    case "LIGHTBLUE":
      return "FFADD8E6";
    case "LIGHTGREEN":
      return "FF90EE90";
    default:
      throw new Error(`Unsupported color: ${htmlColor}`);
  }
}

function cellColor(htmlColor: string) {
  return () => ({
    style: {
      backgroundColor: htmlColor,
      borderColor: htmlColor,
    },
  });
}

const dividerColumn: SharedColumn<EntryView> = {
  width: 2,
  onHeaderCell: cellColor(BORDER_COLOR),
  onCell: cellColor(BORDER_COLOR),
};

function getAllocationColumns(
  date: dayjs.Dayjs,
  iperiod: number,
  excel: boolean
): SharedColumn<EntryView>[] {
  return [
    {
      title: date.add(iperiod + 1, "month").format("MMM YYYY"),
      children: [
        dividerColumn,
        {
          title: "Redemptions",
          width: 100,
          render: (_, entry) => {
            const value = entry.balances[iperiod]?.redemptions;
            return excel ? value : formatCurrency(value);
          },
          excelNumFmt: excelFormatCurrency(),
          onCell: entry => {
            const redemptionSource = entry.balances[iperiod]?.redemptionsSource;
            return {
              style: {
                backgroundColor:
                  redemptionSource ===
                  RedemptionsSource.RedemptionsSystemTicketApproved
                    ? "pink"
                    : redemptionSource ===
                        RedemptionsSource.RedemptionsSystemTicketConfirmed
                      ? "cyan"
                      : undefined,
              },
            };
          },
          key: "redemptions",
          align: "right",
        },
        {
          title: "Additions",
          width: 100,
          render: (_, entry) => {
            const value = entry.balances[iperiod]?.additions;
            return excel ? value : formatCurrency(value);
          },
          excelNumFmt: excelFormatCurrency(),
          key: "additions",
          align: "right",
        },
        {
          title: "End Allocation",
          width: 100,
          render: (_, entry) => {
            const value = entry.balances[iperiod]?.endBalance;
            return excel ? value : formatCurrency(value);
          },
          excelNumFmt: excelFormatCurrency(),
          key: "endBalance",
          align: "right",
        },
        {
          title: "Weight",
          width: 50,
          render: (_, entry) => {
            const value = entry.balances[iperiod]?.weight;
            return excel ? value : formatPercent(value);
          },
          excelNumFmt: excelFormatPercent(),
          key: "weight",
          align: "right",
        },
        {
          title: "#",
          width: 30,
          render: (_, entry) => {
            if (!entry.isAcct && !entry.managerId) {
              return entry.balances[iperiod].nonZeroCount;
            }
          },
          key: "nonZeroCount",
          align: "right",
        },
      ],
    },
  ];
}

export function getSharedColumns(
  date: dayjs.Dayjs,
  periods: number,
  collapsedGroups: Set<string>,
  setCollapsedGroups: (collapsedGroups: Set<string>) => void,
  excel: boolean
): SharedColumn<EntryView>[] {
  const allocationColumns = Array.from({ length: periods }, (_, i) =>
    getAllocationColumns(date, i, excel)
  ).flat();

  const tableColumns: SharedColumn<EntryView>[] = [
    // fixed columns
    {
      width: 30,
      render: (_, entry) => {
        if (!excel && entry.subitems) {
          return (
            <Button
              size="small"
              onClick={() => {
                const newSet = new Set(collapsedGroups);
                newSet.delete(entry.name) || newSet.add(entry.name);
                setCollapsedGroups(newSet);
              }}
            >
              {collapsedGroups.has(entry.name) ? "+" : "-"}
            </Button>
          );
        }
        return entry.mgrIndex;
      },
      key: "mgrIndex",
      fixed: "left",
      align: "center",
    },
    {
      title: "Manager Name",
      width: 200,
      fixed: "left",
      ellipsis: true,
      render: (_, entry) => {
        if (!excel && entry.managerId && !entry.isAcct) {
          return (
            <Link
              data-cy="investment-link"
              to={{ pathname: `/investments/${entry.managerId}` }}
            >
              {entry.name}
            </Link>
          );
        }
        return entry.name;
      },
      onCell: entry => ({
        colSpan: entry.managerId ? 1 : 6,
      }),
      key: "name",
    },
    {
      title: "Firm",
      width: 40,
      fixed: "left",
      ellipsis: true,
      render: (_, entry) => entry.firmTag,
      onCell: entry => ({
        colSpan: entry.managerId ? 1 : 0,
      }),
      key: "firmTag",
    },
    {
      title: "Client",
      width: 40,
      fixed: "left",
      ellipsis: true,
      render: (_, entry) => entry.clientTag,
      onCell: entry => ({
        colSpan: entry.managerId ? 1 : 0,
      }),
      key: "clientTag",
    },
    {
      title: "Team",
      width: 40,
      fixed: "left",
      ellipsis: true,
      render: (_, entry) => entry.teamTag,
      onCell: entry => ({
        colSpan: entry.managerId ? 1 : 0,
      }),
      key: "teamTag",
    },
    {
      title: "Open",
      width: 40,
      fixed: "left",
      ellipsis: true,
      render: (_, entry) => entry.openTag,
      onCell: entry => ({
        colSpan: entry.managerId ? 1 : 0,
      }),
      key: "openTag",
    },
    {
      title: "Guideline",
      width: 40,
      fixed: "left",
      ellipsis: true,
      render: (_, entry) => entry.guidelineTag,
      onCell: entry => ({
        colSpan: entry.managerId ? 1 : 0,
      }),
      key: "guidelineTag",
    },
    {
      width: 2,
      fixed: "left",
      onHeaderCell: cellColor(BORDER_COLOR),
      onCell: cellColor(BORDER_COLOR),
    },
    // scrolling columns
    {
      title: "Link",
      width: 30,
      render: (_, entry) => {
        if (entry.isLinkMutable) {
          if (excel) {
            return entry.isLinkManager ? "Yes" : "";
          }
          return <Checkbox checked={entry.isLinkManager} />;
        }
      },
      align: "center",
      key: "link",
    },
    {
      width: 2,
      onHeaderCell: cellColor(BORDER_COLOR),
      onCell: cellColor(BORDER_COLOR),
    },
    {
      title: date.format("MMM YYYY"),
      children: [
        {
          title: "End Allocation",
          width: 100,
          render: (_, entry) => {
            const value = entry.initBalance.endBalance;
            return excel ? value : formatCurrency(value);
          },
          excelNumFmt: excelFormatCurrency(),
          align: "right",
          key: "endBalance",
        },
        {
          title: "Weight",
          width: 50,
          render: (_, entry) => {
            const value = entry.initBalance.weight;
            return excel ? value : formatPercent(value);
          },
          excelNumFmt: excelFormatPercent(),
          align: "right",
          key: "weight",
        },
        {
          title: "#",
          width: 30,
          render: (_, entry) => {
            if (!entry.managerId && !entry.isAcct) {
              return entry.initBalance.nonZeroCount;
            }
          },
          align: "right",
          key: "nonZeroCount",
        },
      ],
    },
    {
      title: "MTD Update",
      onHeaderCell: cellColor("gold"),
      children: [
        {
          title: "Est Rtn",
          width: 50,
          onHeaderCell: cellColor("gold"),
          render: (_, entry) => {
            if (entry.isLinkManager || (!entry.managerId && !entry.isAcct)) {
              const value = entry.initTran?.estimatedReturn;
              return excel
                ? value
                : formatPercent(entry.initTran?.estimatedReturn);
            }
          },
          excelNumFmt: excelFormatPercent(),
          align: "right",
          key: "estimatedReturn",
        },
        {
          title: "Rtn Date",
          width: 60,
          onHeaderCell: cellColor("gold"),
          render: (_, entry) => {
            if (entry.isLinkManager) {
              return formatDate(entry.initTran?.estimatedReturnDate);
            }
          },
          align: "right",
          key: "estimatedReturnDate",
        },
      ],
    },
    ...allocationColumns,
    dividerColumn,
    {
      title: "Redemption",
      onHeaderCell: cellColor("LightGreen"),
      children: [
        {
          title: "Notice",
          width: 60,
          align: "right",
          onHeaderCell: cellColor("LightGreen"),
          onCell: entry => ({
            style: {
              backgroundColor: entry.isNoticePast
                ? "pink"
                : entry.isNoticeSoon
                  ? "LightGreen"
                  : undefined,
            },
          }),
          render: (_, entry) => {
            if (entry.isLinkManager) {
              return formatDate(entry.liquidity?.nextNotice);
            }
          },
          key: "nextNotice",
        },
        {
          title: "Date",
          width: 60,
          onHeaderCell: cellColor("LightGreen"),
          render: (_, entry) => {
            if (entry.isLinkManager) {
              return formatDate(entry.liquidity?.nextRedemption);
            }
          },
          align: "right",
          key: "nextRedemption",
        },
        {
          title: "Qty",
          width: 60,
          onHeaderCell: cellColor("LightGreen"),
          render: (_, entry) => {
            if (entry.isLinkManager) {
              const value = entry.liquidity?.quantity;
              return excel ? value : formatPercent(value, 0);
            }
          },
          excelNumFmt: excelFormatPercent(0),
          align: "right",
          key: "quantity",
        },
        {
          title: "Freq",
          width: 60,
          onHeaderCell: cellColor("LightGreen"),
          render: (_, entry) => {
            if (entry.isLinkManager) {
              return entry.liquidity?.frequency;
            }
          },
          align: "right",
          key: "frequency",
        },
      ],
    },
    dividerColumn,
    {
      title: "Commitment",
      align: "right",
      onHeaderCell: cellColor("LightBlue"),
      children: [
        {
          title: "Amount",
          width: 100,
          onHeaderCell: cellColor("LightBlue"),
          onCell: entry => ({
            style: {
              backgroundColor: entry.commitment?.isPreInvestment
                ? "pink"
                : undefined,
            },
          }),
          render: (_, entry) => {
            const value = entry.commitment?.amount;
            return excel ? value : formatCurrency(value, 0);
          },
          key: "amount",
          align: "right",
        },
      ],
    },
  ];

  // set row styles
  const leafColumns = tableColumns.flatMap(
    column => column.children ?? [column]
  );
  leafColumns.forEach(column => {
    const { onCell } = column;
    column.onCell = entry => {
      const attrCell = onCell?.(entry);
      const attrRow = !entry.managerId
        ? {
            style: {
              fontWeight: "bold",
              backgroundColor:
                typeof entry.key !== "string"
                  ? undefined
                  : entry.key.startsWith("mgr::") ||
                      entry.key.startsWith("acct::")
                    ? "#DFDFFF"
                    : entry.key.startsWith("total::")
                      ? "#BFBFFF"
                      : entry.key.startsWith("cash::")
                        ? "#DDFFDD"
                        : undefined,
            },
          }
        : entry.isLookthrough
          ? { style: { backgroundColor: "#DCFFDC" } }
          : {};
      return merge(attrRow, attrCell);
    };
  });

  return tableColumns;
}

function isExcelValue(
  value: any
): value is React.ReactNode & ExcelJS.CellValue {
  return (
    typeof value === "boolean" ||
    typeof value === "number" ||
    typeof value === "string" ||
    typeof value === "undefined" ||
    value === null ||
    value instanceof Date
  );
}

function formatHeaderCell(cell: ExcelJS.Cell, attrs?: HeaderAttrs<EntryView>) {
  const backgroundColor = attrs?.style?.backgroundColor;
  const backgroundArgb = htmlColorToArgb(backgroundColor);
  cell.font = { bold: true };
  cell.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: backgroundArgb ?? htmlColorToArgb(HEADER_COLOR) },
  };

  const borderColor = attrs?.style?.borderColor;
  const borderArgb = htmlColorToArgb(borderColor);
  const border: Partial<ExcelJS.Border> = {
    style: "thin",
    color: { argb: borderArgb ?? htmlColorToArgb(BORDER_COLOR) },
  };
  cell.border = {
    top: border,
    left: border,
    bottom: border,
    right: border,
  };
  cell.alignment = { vertical: "bottom" };
}

export async function getExcelBlob(
  dataSource: EntryView[],
  date: dayjs.Dayjs,
  periods: number
) {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet();
  const columns = getSharedColumns(date, periods, new Set(), () => {}, true);

  const pixelsPerChar = (7.5 * 8) / 11;
  const pixelsToChars = (pixels: number) => pixels / pixelsPerChar;

  const leafColumns = columns.flatMap(column => column.children ?? [column]);

  // define columns
  const xlCols: Partial<ExcelJS.Column>[] = [];
  for (const leafColumn of leafColumns) {
    const width = pixelsToChars(leafColumn.width!);
    xlCols.push({
      width,
      font: { size: 8 },
      alignment: { horizontal: leafColumn.align },
      numFmt: leafColumn.excelNumFmt,
    });
  }
  ws.columns = xlCols;

  // head rows
  let coloffset = 1;
  columns.forEach((col, icol) => {
    const colidx = icol + coloffset;
    const children = col.children ?? [];
    const cell = ws.getCell(1, colidx);
    const attrs = col.onHeaderCell?.();
    formatHeaderCell(cell, attrs);
    cell.value = col.title;
    cell.alignment.horizontal = col.align ?? "left";
    if (children.length === 0) {
      ws.mergeCells(1, colidx, 2, colidx);
    } else {
      if (children.length > 1) {
        ws.mergeCells(1, colidx, 1, colidx + children.length - 1);
        coloffset += children.length - 1;
        cell.alignment.horizontal = "center";
      }
      children.forEach((child, ichild) => {
        const cell = ws.getCell(2, colidx + ichild);
        const attrs = child.onHeaderCell?.();
        formatHeaderCell(cell, attrs);
        cell.value = child.title;
        cell.alignment.horizontal = child.align ?? "left";
      });
    }
  });

  // data rows
  dataSource.forEach((entry, irow) => {
    const rowidx = irow + 3;
    leafColumns.forEach((column, icol) => {
      const colidx = icol + 1;
      const attrs = column.onCell?.(entry, irow);
      const colSpan = attrs?.colSpan ?? 1;
      if (!colSpan) return;
      if (colSpan > 1) {
        ws.mergeCells(rowidx, colidx, rowidx, colidx + colSpan - 1);
      }
      const cell = ws.getCell(rowidx, colidx);
      const backgroundColor = attrs?.style?.backgroundColor;
      if (backgroundColor) {
        const argb = htmlColorToArgb(backgroundColor);
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb },
        };
      }
      const borderColor = attrs?.style?.borderColor;
      if (borderColor) {
        const argb = htmlColorToArgb(borderColor);
        const border: Partial<ExcelJS.Border> = {
          style: "thin",
          color: { argb },
        };
        cell.border = {
          top: border,
          left: border,
          bottom: border,
          right: border,
        };
      }
      const fontWeight = attrs?.style?.fontWeight;
      if (fontWeight === "bold") {
        cell.font = { bold: true };
      }
      const value = column.render?.(undefined, entry, irow);
      if (!isExcelValue(value)) {
        throw new Error(`Invalid cell data type for excel: ${value}`);
      }
      cell.value = value;
      cell.alignment = { horizontal: column.align ?? "left" };
      if (column.excelNumFmt) {
        cell.numFmt = column.excelNumFmt;
      }
    });
  });

  const buffer = await wb.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  return blob;
}

const getEntryView = (portfolio: Portfolio, entry: Entry): EntryView => {
  const isAcct = !!entry.initBalance.acctManager;
  const name = isAcct
    ? entry.initBalance.acctManager?.name || ""
    : entry.initBalance.manager?.shortName ||
      entry.initBalance.manager?.name ||
      "";
  const firmTag = entry.initBalance.manager?.strategy?.code ?? "";
  const clientTag = entry.initBalance.tags?.clientTag ?? "";
  const teamTag = entry.initBalance.tags?.teamTag ?? "";
  const openTag = entry.initBalance.tags?.openTag ?? "";
  const guidelineTag = entry.initBalance.tags?.guidelineTag ?? "";
  const isLookthrough = !!entry.isLookthrough;
  const isLinkManager = !!(portfolio?.isPrimary || entry.isLinked) && !isAcct;
  const isLinkMutable = !portfolio?.isPrimary && !isAcct;

  const view: EntryView = {
    key: entry.managerId,
    managerId: entry.managerId,
    isAcct,
    name,
    firmTag,
    clientTag,
    teamTag,
    openTag,
    guidelineTag,
    isLookthrough,
    isLinkManager,
    isLinkMutable,
    initTran: !entry.initTran
      ? undefined
      : {
          beginBalance: entry.initTran.beginBalance,
          estimatedReturn: entry.initTran.estimatedReturn ?? undefined,
          estimatedReturnDate: parseDate(entry.initTran.estimatedReturnDate),
        },
    initBalance: {
      additions: 0,
      redemptions: 0,
      endBalance: entry.initBalance.endBalance,
      weight: entry.initBalance.weight,
      nonZeroCount:
        !isAcct &&
        !IGNORE_TAGS.includes(clientTag) &&
        entry.initBalance.endBalance
          ? 1
          : 0,
    },
    balances: entry.balances.map(balance => ({
      additions: balance.additions,
      redemptions: balance.redemptions,
      redemptionsSource: balance.redemptionsSource ?? undefined,
      endBalance: balance.endBalance,
      weight: balance.weight,
      nonZeroCount:
        !isAcct && !IGNORE_TAGS.includes(clientTag) && balance.endBalance
          ? 1
          : 0,
    })),
    liquidity: !entry.liquidity
      ? undefined
      : {
          nextNotice: parseDate(entry.liquidity.nextNotice),
          nextRedemption: parseDate(entry.liquidity.nextRedemption),
          quantity: entry.liquidity.quantity ?? undefined,
          frequency: entry.liquidity.frequency ?? undefined,
        },
    commitment: entry.commitment ?? undefined,
  };

  if (view.isLinkManager) {
    const nextNotice = view.liquidity?.nextNotice;
    if (nextNotice) {
      if (nextNotice.isBefore(dayjs())) {
        view.isNoticePast = true;
      } else if (nextNotice.isBefore(dayjs().add(1, "month"))) {
        view.isNoticeSoon = true;
      }
    }
  }

  return view;
};

const sumInitTrans = (items: EntryView[]): TranView => {
  let beginBalance = 0;
  let delta = 0;
  let count = 0;
  for (const item of items) {
    if (!item.initTran) continue;
    beginBalance += item.initTran.beginBalance;
    if (typeof item.initTran.estimatedReturn !== "number") continue;
    delta += item.initTran.beginBalance * item.initTran.estimatedReturn;
    ++count;
  }
  return {
    beginBalance,
    estimatedReturn: count && beginBalance ? delta / beginBalance : undefined,
  };
};

const sumBalances = (items: EntryView[]) => {
  const balances: BalanceView[] = Array.from(
    { length: MAX_PERIODS + 1 },
    () => ({
      additions: 0,
      redemptions: 0,
      endBalance: 0,
      weight: 0,
      nonZeroCount: 0,
    })
  );
  for (const item of items) {
    [item.initBalance, ...item.balances].forEach((balance, i) => {
      balances[i].additions += balance.additions;
      balances[i].redemptions += balance.redemptions;
      balances[i].endBalance += balance.endBalance;
      balances[i].weight += balance.weight;
      balances[i].nonZeroCount += balance.nonZeroCount;
    });
  }
  return balances;
};

export const getGroups = (groupBy: GroupBy, portfolio?: Portfolio | null) => {
  const entries = portfolio?.entries;
  if (!entries) return;
  const mgrEntries = entries.filter(entry => !entry.initBalance.acctManager);
  const mgrGroupMap = new Map<string, EntryView[]>();
  for (const entry of mgrEntries) {
    // initial space is to make them sort first
    const name = GROUP_BY[groupBy](entry) || " " + GROUP_BY.firm(entry);
    const subitems = mgrGroupMap.get(name) ?? [];
    const subitem = getEntryView(portfolio, entry);
    subitems.push(subitem);
    mgrGroupMap.set(name, subitems);
  }

  const groups = [...mgrGroupMap].map(([name, subitems]) => {
    const initTran = sumInitTrans(subitems);
    console.log(name, initTran);
    const [initBalance, ...balances] = sumBalances(subitems);
    const mgrGroup: EntryView = {
      key: `mgr::${name}`,
      isAcct: false,
      name,
      initTran,
      initBalance,
      balances,
      subitems,
    };
    return mgrGroup;
  });

  let index = 0;
  for (const group of groups.sort(nameSort)) {
    for (const subitem of group.subitems!.sort(nameSort)) {
      subitem.mgrIndex = ++index;
    }
  }

  const acctEntries = entries.filter(entry => entry.initBalance.acctManager);
  {
    const subitems = acctEntries
      .sort(acctSort)
      .map(entry => getEntryView(portfolio, entry));
    const initTran = sumInitTrans(subitems);
    const [initBalance, ...balances] = sumBalances(subitems);
    const name = "Accounting";
    const acctGroup: EntryView = {
      isAcct: true,
      key: `acct::${name}`,
      name,
      initTran,
      initBalance,
      balances,
      subitems,
    };
    groups.push(acctGroup);
  }
  {
    const subitems = groups;
    const initTran = sumInitTrans(subitems);
    const [initBalance, ...balances] = sumBalances(subitems);
    const name = "Total";
    const totalGroup: EntryView = {
      isAcct: false,
      key: `total::${name}`,
      name,
      initTran,
      initBalance,
      balances,
      // no subitems
    };
    groups.push(totalGroup);
  }
  {
    const subitems = acctEntries
      .filter(entry => INVESTABLE_CASH_ACCT_TYPES.includes(entry.managerId))
      .map(entry => getEntryView(portfolio, entry));
    const [initBalance, ...balances] = sumBalances(subitems);
    const name = "Investable Cash";
    const cashGroup: EntryView = {
      isAcct: true,
      key: `cash::${name}`,
      name,
      // no initTran
      initBalance,
      balances,
      // no subitems
    };
    // insert before total group
    groups.splice(-1, 0, cashGroup);
  }

  console.log("GROUPS ->", groups);
  return groups;
};

export const getDataSource = (
  collapsedGroups: Set<string> | undefined,
  groups?: EntryView[]
) => {
  if (!groups) return;
  const dataSource = groups.flatMap(group => {
    const expanded = !collapsedGroups?.has(group.name);
    const subitems = (expanded && group.subitems) || [];
    return [...subitems, group];
  });

  console.log("DATASOURCE ->", dataSource);
  return dataSource;
};
