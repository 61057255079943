import React, { useCallback, useMemo, useState, createContext } from "react";
import { Alert, Button, DatePicker, Modal, Space } from "antd";
import DiligenceReport from "./Diligence/Diligence.component";
import { fundReportContext, useFundReportContext } from "./fundReport.context";
import CusumReport from "./Cusum";

import { styled } from "@mui/material";

const Footer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
}));

export default function ReportModal({
  investmentId,
}: {
  investmentId: number;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentReport, changeCurrentReport] = useState<"diligence" | "cusum">(
    "diligence"
  );

  const contextValue = useFundReportContext();
  const { loading, error, reportGenerateDate, changeReportGenerateDate } =
    contextValue;

  return (
    <fundReportContext.Provider value={contextValue}>
      <button
        data-cy="report-modal-button"
        type="button"
        className="round-icon-btn round-icon-btn--36 round-icon-btn--transparent-white ml-15 mb-10 hidden-lg-down"
        onClick={() => setIsOpen(true)}
      >
        <i className="icon icon-print font-15" />
      </button>
      <Modal
        title={I18n.t("reports.create_modal.title")}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={
          <Footer>
            <div>
              <button
                type="button"
                className="rounded-btn rounded-btn--grey mr-10 mb-15"
                onClick={() => setIsOpen(false)}
              >
                {I18n.t("funds.form.cancel")}
              </button>
              <button
                type="submit"
                id="download-report-button"
                className="rounded-btn rounded-btn--blue mb-15 download-report"
                onClick={() => {
                  changeReportGenerateDate(new Date());
                }}
              >
                {loading ? (
                  <i
                    id="loading-more-spinner"
                    className="main-spinner__icon"
                    style={{ color: "#ffffff", fontSize: "12px" }}
                  />
                ) : (
                  I18n.t("create_report")
                )}
              </button>
            </div>
          </Footer>
        }
      >
        <span className="create-report-modal-content">
          <Button
            type={currentReport == "diligence" ? "primary" : undefined}
            onClick={() => changeCurrentReport("diligence")}
          >
            Diligence
          </Button>
          <Button
            type={currentReport == "cusum" ? "primary" : undefined}
            onClick={() => changeCurrentReport("cusum")}
          >
            Cusum
          </Button>
          {currentReport == "cusum" && (
            <CusumReport investmentId={investmentId} />
          )}
          {currentReport == "diligence" && (
            <DiligenceReport investmentId={investmentId} />
          )}
        </span>
        {error && (
          <Space>
            <Alert
              message="Error Generating Report"
              description={error.message}
              type="error"
            />
          </Space>
        )}
      </Modal>
    </fundReportContext.Provider>
  );
}
