/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import { TypedDocumentNode, gql, useApolloClient } from "@apollo/client";
import {
  FormatOptions,
  ReportStructure,
  ReportTypeOptions,
  SubSections,
} from "_graphql-types/graphql";
import { Alert, Modal, Space } from "antd";
import { saveAs } from "file-saver";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  ASYNC_TASK_STATUS_QUERY,
  GET_REPORT,
  INVESTMENT_MARKET,
  SUBMIT_REPORT_JOB,
} from "./graphql";

import { styled } from "@mui/material";
import { fundReportContext } from "../fundReport.context";

const Footer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
}));

type GqlVars<T> = T extends TypedDocumentNode<any, infer V> ? V : never;

const CommonOverviewSubSections = [
  SubSections.OverviewInvestmentSummaryData,
  SubSections.OverviewFirmTileData,
  SubSections.OverviewClassificationsTileData,
  SubSections.OverviewStatusTileData,
  SubSections.OverviewFundTileData,
  SubSections.OverviewFundTermsData,
  SubSections.OverviewContactsData,
];

const PublicOverviewSubSections = [
  ...CommonOverviewSubSections,
  SubSections.OverviewServiceProvidersData,
  SubSections.OverviewPerformanceData,
  SubSections.OverviewPeerStatisticsData,
  SubSections.OverviewPositionsData,
  SubSections.OverviewBenchmarkStatisticsData,
  SubSections.OverviewRiskStatisticsData,
  SubSections.OverviewRiskBenchmarkStatisticsData,
  SubSections.OverviewMaxDrawDownStatisticsData,
];

const PrivateOverviewSubSections = [
  ...CommonOverviewSubSections,
  SubSections.OverviewPrivateInvestmentFamilyData,
  SubSections.OverviewPrivatePerformanceData,
  SubSections.OverviewPrivatePositionsData,
];

const DiligenceSubSections = [
  SubSections.DiligenceInvestment,
  SubSections.DiligenceOperations,
  SubSections.DiligenceImpact,
];

const TeamSubSections = [
  SubSections.TeamTeamSummary,
  SubSections.TeamKeyPersonnel,
];

const PublicAnalyticsSubSections = [
  SubSections.AnalyticsAssetClassExposure,
  SubSections.AnalyticsBenchmarkReturns,
  SubSections.AnalyticsCumulativeReturns,
  SubSections.AnalyticsDistributionOfReturns,
  SubSections.AnalyticsDrawdowns,
  SubSections.AnalyticsFirmAum,
  SubSections.AnalyticsGrowthOf_1000,
  SubSections.AnalyticsInvestmentAum,
  SubSections.AnalyticsPortfolioExposure,
  SubSections.AnalyticsRegionExposure,
  SubSections.AnalyticsRiskVsReturns,
  SubSections.AnalyticsSectorExposure,
  SubSections.AnalyticsStrategyAum,
  SubSections.AnalyticsUpDownCapture,
];

const PrivateAnalyticsSubSections = [
  SubSections.AnalyticsPortfolioExposure,
  SubSections.AnalyticsRegionExposure,
  SubSections.AnalyticsSectorExposure,
];

export default function DiligenceReport({
  investmentId,
}: {
  investmentId: number;
}) {
  const client = useApolloClient();

  const { setError, setLoading, reportGenerateDate, changeReportGenerateDate } =
    useContext(fundReportContext);

  const [sendEmail, setSendEmail] = useState(false);

  const investmentMarketFragment = client.readFragment({
    id: `Investment:${investmentId}`,
    fragment: INVESTMENT_MARKET,
  });

  if (!investmentMarketFragment || !investmentMarketFragment.market) {
    throw Error("Investment not found");
  }
  const { name, market } = investmentMarketFragment;

  const isPublic = market.id === 1;

  const sectionSubSectionsMap: { [k in ReportStructure]: SubSections[] } = {
    [ReportStructure.Overview]: isPublic
      ? PublicOverviewSubSections
      : PrivateOverviewSubSections,
    [ReportStructure.Diligence]: DiligenceSubSections,
    [ReportStructure.Team]: TeamSubSections,
    [ReportStructure.Analytics]: isPublic
      ? PublicAnalyticsSubSections
      : PrivateAnalyticsSubSections,
  };

  const orderedSubsections = Object.values(sectionSubSectionsMap).flat();

  const [isOpen, setIsOpen] = useState(false);
  const [formatOption, setFormatOption] = useState<FormatOptions>(
    FormatOptions.Pdf
  );
  const [reportType, setReportType] = useState<ReportTypeOptions>(
    ReportTypeOptions.Summary
  );
  const [sections, setSections] = useState<ReportStructure[]>([]);
  const [subSections, setSubSections] = useState<SubSections[]>([]);

  const toggleSections = (section: ReportStructure) => {
    if (sections.includes(section)) {
      setSections(sections.filter(s => s !== section));
      setSubSections(
        subSections.filter(s => !sectionSubSectionsMap[section].includes(s))
      );
    } else {
      setSections([...sections, section]);
      setSubSections(
        Array.from(new Set([...subSections, ...sectionSubSectionsMap[section]]))
      );
    }
  };

  const [submitReportJob, { data }] = useLazyWorkerQuery();

  function useLazyWorkerQuery() {
    const [data, setData] = React.useState<any>(null);

    const client = useApolloClient();

    const query = useCallback(
      (createJobVariables: GqlVars<typeof SUBMIT_REPORT_JOB>) => {
        setLoading(true);
        setError(undefined);
        setData(null);

        return client
          .mutate({
            mutation: SUBMIT_REPORT_JOB,
            variables: createJobVariables,
            fetchPolicy: "network-only",
          })
          .then(async ({ data }) => {
            if (!data) throw Error("data is undefined");
            const jobId = data["investmentReport"].id;
            if (!jobId) throw Error("jobId is undefined");

            for (let i = 0; i < 1440; i++) {
              const {
                data: { asyncTaskStatus },
              } = await client.query({
                query: ASYNC_TASK_STATUS_QUERY,
                variables: {
                  id: jobId,
                },
                fetchPolicy: "network-only",
              });

              if (asyncTaskStatus) {
                const { error, id, endDate } = asyncTaskStatus;
                if (!id) throw Error("asyncTaskStatus.id is undefined");

                if (error) {
                  throw new Error(error);
                }
                if (endDate) {
                  return client
                    .query({
                      query: GET_REPORT,
                      variables: {
                        id,
                        format: formatOption,
                      },
                      fetchPolicy: "network-only",
                    })
                    .then(({ data }) => {
                      setData(data);
                      setLoading(false);
                      return data;
                    })
                    .catch(e => {
                      setLoading(false);
                      setError(e);
                      throw e;
                    });
                }
              }

              await new Promise(resolve => setTimeout(resolve, 1000));
            }

            throw new Error("Timed out polling for job");
          })
          .catch(e => {
            setLoading(false);
            setError(e);
            throw e;
          });
      },
      [client, formatOption]
    );

    return [query, { data }] as const;
  }

  useEffect(() => {
    if (data?.report) {
      fetch(data.report)
        .then(response => response.blob())
        .then(blob => saveAs(blob, `${name}.${formatOption}`));
    }
  }, [data?.report]);

  const subSectionCheckBox = (subSection: SubSections) => (
    <div key={subSection} className="main-checkbox">
      <input
        id={`custom-report-element-option-${subSection}`}
        type="checkbox"
        name={`elements[${subSection}]`}
        checked={subSections.includes(subSection)}
        onChange={() =>
          setSubSections(
            subSections.includes(subSection)
              ? subSections.filter(s => s !== subSection)
              : orderedSubsections.filter(item =>
                  [...subSections, subSection].includes(item)
                ) // we want to keep the order of the subSections
          )
        }
        className="main-checkbox__input"
      />

      <label
        className="main-checkbox__label"
        htmlFor={`custom-report-element-option-${subSection}`}
      >
        {I18n.t(
          `reports.create_modal.sub_sections.${subSection.substring(
            subSection.indexOf("_") + 1
          )}`
        )}
      </label>
    </div>
  );

  useEffect(() => {
    if (!reportGenerateDate) {
      return;
    }
    if (sendEmail) {
      setIsOpen(false);
    }
    submitReportJob({
      investmentId,
      format: formatOption,
      reportType,
      sendEmail,
      ...(reportType === ReportTypeOptions.Custom
        ? { elements: sections, subSections }
        : {}),
    }).then(() => {
      changeReportGenerateDate && changeReportGenerateDate(undefined);
    });
  }, [
    reportGenerateDate,
    investmentId,
    formatOption,
    reportType,
    sendEmail,
    sections,
    subSections,
  ]);

  return (
    <>
      <div className="d-flex">
        {Object.values(FormatOptions)
          .reverse()
          .map(format => (
            <div key={format} className="main-radio mr-30">
              <input
                id={`report-format-option-${format}`}
                type="radio"
                name="format"
                value={format}
                checked={formatOption === format}
                onChange={() => setFormatOption(format)}
                className="main-radio__input"
              />
              <label
                htmlFor={`report-format-option-${format}`}
                className="main-radio__label"
              >
                {format.toUpperCase()}
              </label>
            </div>
          ))}
      </div>
      <div className="divider mb-15" />
      <div className="form-group">
        {[
          ReportTypeOptions.Summary,
          ReportTypeOptions.Complete,
          ReportTypeOptions.Custom,
          ReportTypeOptions.Ranger,
          ReportTypeOptions.TwoPage,
        ].map(type => (
          <div key={type} className="main-radio">
            <input
              id={`report-type-option-${type}`}
              type="radio"
              name="type"
              value={I18n.t(`reports.create_modal.types.${type.toLowerCase()}`)}
              checked={reportType === type}
              onChange={() => setReportType(type)}
              className="main-radio__input"
            />

            <label
              className="main-radio__label"
              htmlFor={`report-type-option-${type}`}
            >
              {type === "TwoPage" ? "Two-Page Report" : type}
            </label>
            {reportType === ReportTypeOptions.Custom &&
              type === ReportTypeOptions.Custom && (
                <div style={{ display: "flex" }}>
                  <div className="pl-30">
                    {[
                      ReportStructure.Overview,
                      ReportStructure.Diligence,
                      ReportStructure.Team,
                      ReportStructure.Analytics,
                    ].map(element => (
                      <div key={element} className="main-checkbox">
                        <input
                          id={`custom-report-element-option-${element}`}
                          type="checkbox"
                          name={`elements[${element}]`}
                          checked={sections.includes(element)}
                          onChange={() => toggleSections(element)}
                          className="main-checkbox__input"
                        />

                        <label
                          className="main-checkbox__label"
                          htmlFor={`custom-report-element-option-${element}`}
                        >
                          {element}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div
                    className="pl-30"
                    style={{ maxHeight: "200px", overflow: "scroll" }}
                  >
                    {sections.includes(ReportStructure.Overview) && (
                      <>
                        <div>Overview</div>
                        {sectionSubSectionsMap[ReportStructure.Overview].map(
                          subSectionCheckBox
                        )}
                      </>
                    )}
                    {sections.includes(ReportStructure.Diligence) && (
                      <>
                        <div>Diligence</div>
                        {DiligenceSubSections.map(subSectionCheckBox)}
                      </>
                    )}
                    {sections.includes(ReportStructure.Team) && (
                      <>
                        <div>Team</div>
                        {TeamSubSections.map(subSectionCheckBox)}
                      </>
                    )}
                    {sections.includes(ReportStructure.Analytics) && (
                      <>
                        <div>Analytics</div>
                        {sectionSubSectionsMap[ReportStructure.Analytics].map(
                          subSectionCheckBox
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
          </div>
        ))}
      </div>
      <input
        id="send-email-checkbox"
        type="checkbox"
        checked={sendEmail}
        onChange={() => setSendEmail(!sendEmail)}
        className="main-checkbox__input"
      />
      <label htmlFor="send-email-checkbox" className="main-checkbox__label">
        Receive as Email
      </label>
    </>
  );
}
