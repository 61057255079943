import { gql } from "src/graphql-types";

export const QUERY_ENTRIES = gql(`
  query getEntries(
    $portfolioId: Int!
    $date: Date!
    $periods: Int!
    $lookthrough: Boolean!
  ) {
    portfolio(id: $portfolioId) {
      isPrimary
      entries(date: $date, periods: $periods, lookthrough: $lookthrough) {
        managerId
        isLookthrough
        isLinked
        initTran {
          date
          beginBalance
          estimatedReturn
          estimatedReturnDate
        }
        initBalance {
          date
          endBalance
          weight
          manager {
            id
            name
            shortName
            strategy {
              id
              name
              code
              displayOrder
            }
          }
          acctManager {
            id
            name
          }
          tags {
            date
            clientTag: tag
            teamTag: tag2
            openTag: tag3
            guidelineTag: tag4
          }
        }
        balances {
          date
          additions
          redemptions
          redemptionsSource
          endBalance
          weight
        }
        liquidity {
          nextNotice
          nextRedemption
          quantity
          frequency
        }
        commitment {
          amount
          isPreInvestment
        }
      }
    }
  }
`);

export const PORTFOLIO_FRAGMENT = gql(`
  fragment PortfolioFragment on Portfolio {
    id
    name
    isPrimary
    investment {
      id
      name
    }
    dateRange {
      start
      end
    }
  }
`);

export const QUERY_PORTFOLIO = gql(`
  query getPortfolio($id: Int!) {
    portfolio(id: $id) {
      ...PortfolioFragment
    }
  }
`);

export const QUERY_PORTFOLIOS = gql(`
  query getPortfolios(
    $category: PortfolioCategoryEnum
    $nameSearch: String  
  ) {
    portfolioList(
      filter: { category: $category, nameSearch: $nameSearch }
      sort: { field: nameSearchRank, order: ASC }
      page: { limit: 10 }
    ) {
      items {
        ...PortfolioFragment
      }
    }
  }
`);
