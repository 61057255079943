import { gql } from "_graphql-types/gql";
import { GET_INVESTMENT_BENCHMARKS as _GET_INVESTMENT_BENCHMARKS } from "Components/AddBenchmark/graphql";

export const GET_CUSUM_REPORT = gql(/* GraphQL */ `
  query cusumReport(
    $investmentId: Int!
    $benchmarkId: Int!
    $startDate: Date!
    $endDate: Date!
  ) {
    cusumReport(
      investmentId: $investmentId
      benchmarkId: $benchmarkId
      startDate: $startDate
      endDate: $endDate
    )
  }
`);

export const GET_BENCHMARKS = gql(/* GraphQL */ `
  query GetBenchmarks($q: String) {
    investmentList(
      filter: { strategy: { strategyIds: [13] }, active: true, q: $q }
      page: { limit: 10 }
    ) {
      items {
        id
        name
      }
    }
  }
`);

export const GET_INVESTMENT_BENCHMARKS = _GET_INVESTMENT_BENCHMARKS;
