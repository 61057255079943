import { ApolloError } from "@apollo/client";

import { createContext, useMemo, useState } from "react";

type FundReportContextType = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  error: ApolloError | undefined;
  setError: (error: ApolloError | undefined) => void;
  reportGenerateDate: Date | undefined;
  changeReportGenerateDate: (
    value: React.SetStateAction<Date | undefined>
  ) => void;
};

export function useFundReportContext() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [reportGenerateDate, changeReportGenerateDate] = useState<
    Date | undefined
  >();

  const contextValue = useMemo(
    () => ({
      loading,
      setLoading,
      error,
      setError,
      reportGenerateDate,
      changeReportGenerateDate,
    }),
    [
      loading,
      setLoading,
      error,
      setError,
      reportGenerateDate,
      changeReportGenerateDate,
    ]
  );
  return contextValue;
}

export const fundReportContext = createContext<FundReportContextType>({
  loading: false,
  setLoading: (loading: boolean) => {},
  error: undefined,
  setError: (error: ApolloError | undefined) => {},
  reportGenerateDate: undefined,
  changeReportGenerateDate: (
    value: React.SetStateAction<Date | undefined>
  ) => {},
});
