import React, { useContext, useEffect, useState } from "react";
import { Alert, AutoComplete, DatePicker, Space } from "antd";
import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { startOfMonth, endOfMonth, format } from "date-fns";
import {
  GET_CUSUM_REPORT,
  GET_BENCHMARKS,
  GET_INVESTMENT_BENCHMARKS,
} from "./graphql";
import dayjs from "dayjs";
import { DefaultOptionType } from "antd/es/select";
import saveAs from "file-saver";
import { fundReportContext } from "../fundReport.context";
import { INVESTMENT_MARKET } from "../Diligence/graphql";

export default function CusumReport({
  investmentId,
}: {
  investmentId: number;
}) {
  const client = useApolloClient();

  const [term, setTerm] = useState("");
  const [benchmarkId, setBenchmarkId] = useState<number | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();

  const investmentNameFragment = client.readFragment({
    id: `Investment:${investmentId}`,
    fragment: INVESTMENT_MARKET,
  });

  if (!investmentNameFragment) {
    return <Alert message="Investment not found" type="error" />;
  }

  const { name, inceptionDate } = investmentNameFragment;

  const [getCusumReport, { loading, error }] = useLazyQuery(GET_CUSUM_REPORT);
  const { data: benchmarksData } = useQuery(GET_BENCHMARKS, {
    variables: { q: term },
  });
  const { data: investmentBenchmarksData } = useQuery(
    GET_INVESTMENT_BENCHMARKS,
    {
      variables: { investmentId },
    }
  );

  const { setError, setLoading, reportGenerateDate, changeReportGenerateDate } =
    useContext(fundReportContext);

  useEffect(() => {
    if (investmentBenchmarksData?.investment.primaryBenchmark) {
      setBenchmarkId(investmentBenchmarksData.investment.primaryBenchmark.id);
      setTerm(investmentBenchmarksData.investment.primaryBenchmark.name);
    }
    if (investmentBenchmarksData?.investment?.performancePeriodRange?.end) {
      setEndDate(
        dayjs(
          investmentBenchmarksData.investment.performancePeriodRange.end
        ).toDate()
      );
    }
    
    if (
      investmentBenchmarksData?.investment?.performancePeriodRange?.start &&
      dayjs(
        investmentBenchmarksData?.investment?.performancePeriodRange?.start
      ).toDate() > dayjs(inceptionDate).toDate()
    ) {
      setStartDate(
        dayjs(
          investmentBenchmarksData?.investment.performancePeriodRange?.start
        ).toDate()
      );
    }
  }, [investmentBenchmarksData, inceptionDate]);

  useEffect(() => {
    if (inceptionDate) {
      setStartDate(startOfMonth(dayjs(inceptionDate).toDate()));
    }
  }, [inceptionDate]);

  useEffect(() => {
    if (!reportGenerateDate) return;
    if (!benchmarkId || !endDate || !startDate) return;

    setError(undefined);
    setLoading(true);
    getCusumReport({
      variables: {
        startDate: format(startDate, "yyyy-MM-dd"),
        endDate: format(endDate, "yyyy-MM-dd"),
        investmentId,
        benchmarkId,
      },
    }).then(data => {
      setLoading(false);

      changeReportGenerateDate && changeReportGenerateDate(undefined);
      if (data.error) {
        setError(data.error);
        return;
      }

      if (!data.data) return;
      fetch(data.data.cusumReport)
        .then(response => response.blob())
        .then(blob => saveAs(blob, `CUSUM Report for ${name}.pdf`));
    });
  }, [reportGenerateDate, startDate, endDate, investmentId, benchmarkId]);

  return (
    <>
      <div className="divider mb-15" />
      <AutoComplete
        options={
          investmentBenchmarksData && benchmarksData
            ? [
                ...(investmentBenchmarksData.investment.primaryBenchmark
                  ? [investmentBenchmarksData.investment.primaryBenchmark]
                  : []),
                ...(investmentBenchmarksData.investment.secondaryBenchmark
                  ? [investmentBenchmarksData.investment.secondaryBenchmark]
                  : []),
                ...benchmarksData.investmentList.items,
              ].map(item => ({
                value: item.id,
                label: item.name,
                data: { id: item.id, name: item.name },
              }))
            : []
        }
        placeholder="Select Benchmark"
        onSearch={value => setTerm(value)}
        onSelect={(_value: string | number, option: DefaultOptionType) => {
          setBenchmarkId(Number(option.value));

          setTerm(option.label?.toString() ?? "");
        }}
        value={term}
      />
      <div className="divider mb-15" />
      <div className="form-group">
        <DatePicker.RangePicker
          picker="month"
          onChange={dates => {
            if (dates) {
              dates[0] && setStartDate(startOfMonth(dates[0].toDate()));
              dates[1] && setEndDate(endOfMonth(dates[1].toDate()));
            }
          }}
          value={[
            startDate ? dayjs(startDate) : undefined,
            endDate ? dayjs(endDate) : undefined,
          ]}
        />
      </div>
    </>
  );
}
