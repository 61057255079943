import { gql } from "_graphql-types/gql";

export const ADD_BENCHMARK = gql(/* GraphQL */ `
  query ADD_BENCHMARK($term: String!) {
    investmentList(
      page: { limit: 10 }
      searchFilters: [{ SEARCH_TERM: { value: $term } }]
    ) {
      items {
        id
        name
      }
      total
    }
  }
`);

export const GET_INVESTMENT_BENCHMARKS = gql(/* GraphQL */ `
  query GetInvestmentBenchmarks($investmentId: Int) {
    investment(id: $investmentId) {
      primaryBenchmark {
        id
        name
      }
      secondaryBenchmark {
        id
        name
      }
      performancePeriodRange {
        start
        end
      }
    }
  }
`);
